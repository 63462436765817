// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/sections/testimonial/Testimonial.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/sections/testimonial/Testimonial.tsx");
}
// REMIX HMR END

import "./testimonial.css?__remix_sideEffect__";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css?__remix_sideEffect__";
import "slick-carousel/slick/slick-theme.css?__remix_sideEffect__";
import { createPublicUrl } from "~/utils/helper";
const sliderSettings = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
export default function Testimonial({
  reviews
}) {
  return <section className="testimonial_section">
      <div className="container">
        <h2 className="heading">Hear From Our Satisfied Clients</h2>

        <Slider className="testimonial_slider" {...sliderSettings}>
          {reviews && reviews.map((review, index) => <div className="swiper-slide" key={index}>
                <div className="testimonial_card">
                  <div className="profile_box">
                    <div className="imgbox">
                      <img src={createPublicUrl(review.image) ? createPublicUrl(review.image) : "/image/no-image.svg"} alt="image" />

                    </div>
                    <div className="text_box">
                      <h2 className="title">{review.name}</h2>
                      <h3 className="subtitle">{review.job_title}</h3>
                    </div>
                  </div>

                  <div className="testimonial_text">
                    <p className="pera">{review.description}</p>
                  </div>
                </div>
              </div>)}
        </Slider>
      </div>
    </section>;
}
_c = Testimonial;
var _c;
$RefreshReg$(_c, "Testimonial");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;