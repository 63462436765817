// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/sections/home-banner/HomeBanner.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/sections/home-banner/HomeBanner.tsx");
}
// REMIX HMR END

import { useState } from "react";
import "./home_banner.css?__remix_sideEffect__";
import Modal from "../../layouts/Modal";
import { Link } from "@remix-run/react";
import { createPublicUrl } from "~/utils/helper";
export default function HomeBanner({
  content
}) {
  _s();
  const [isModalActive, setIsModalActive] = useState(false);

  // useEffect(() => {
  //   if (!isModalActive) {
  //     const videoElement = document.getElementById("bannerVideo");
  //     if (videoElement) {
  //       videoElement.pause(); // Pause the video when modal is closed
  //     }
  //   }
  // }, [isModalActive]);
  return <section className="home_banner_section">
      <div className="content_wrapper">
        <div className="container">
          <div className="row gy-4">
            <div className="col-12 col-md-6 order-2 order-md-1">
              <div className="text_content">
                <h3 className="subheading" onClick={() => setIsModalActive(true)}>

                  <div className="icon_box">
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">

                      <circle cx="26.6875" cy="27.3359" r="26.5" fill="#F6F6F6" />

                      <path d="M34.2721 23.0045C37.6055 24.929 37.6055 29.7402 34.2721 31.6647L26.6452 36.0681C23.3119 37.9927 19.1452 35.587 19.1452 31.738L19.1452 22.9312C19.1452 19.0822 23.3119 16.6766 26.6452 18.6011L34.2721 23.0045Z" fill="#3C4242" />

                    </svg>
                  </div>
                  {content.videTitle}
                </h3>

                <h1 className="heading">{content.bannerTitle}</h1>

                <p className="pera">{content.bannerDescription}</p>

                <div className="button_box">
                  {/* <Link to="/" className="c_btn">
                     GET STARTED
                    </Link> */}
                  <Link to="/join?type=business" className="c_link">
                    Advertise With Us
                    <div className="icon_box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="8" viewBox="0 0 26 8" fill="none">

                        <path d="M25.6036 4.10355C25.7988 3.90829 25.7988 3.59171 25.6036 3.39645L22.4216 0.214466C22.2263 0.0192039 21.9097 0.0192039 21.7145 0.214466C21.5192 0.409728 21.5192 0.726311 21.7145 0.921573L24.5429 3.75L21.7145 6.57843C21.5192 6.77369 21.5192 7.09027 21.7145 7.28553C21.9097 7.4808 22.2263 7.4808 22.4216 7.28553L25.6036 4.10355ZM0.25 4.25H25.25V3.25H0.25V4.25Z" fill="white" />

                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2">
              <div className="image_content">
                <div className="imgbox">
                  <img src={createPublicUrl(content.bannerImage) ? createPublicUrl(content.bannerImage) : "/image/no-image.svg"} alt="image" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isActive={isModalActive} close={() => setIsModalActive(false)}>
        {isModalActive && <video id="bannerVideo" src={createPublicUrl(content.video) ? createPublicUrl(content.video) : "/image/no-image.svg"} controls>
        </video>}

      </Modal>
    </section>;
}
_s(HomeBanner, "TUpuAXXHIk8LQPksACdVv8djDT4=");
_c = HomeBanner;
var _c;
$RefreshReg$(_c, "HomeBanner");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;