// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/layouts/Modal.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/layouts/Modal.tsx");
  import.meta.hot.lastModified = "1723666999155.003";
}
// REMIX HMR END

import React from "react";
export default function Modal({
  isActive,
  children,
  close
}) {
  return <div className={`modal ${isActive && 'active'}`}>
      <div className="modal_inner">
        <div className="top_bar">
          <h2 className="title">Learn about All IN ONE</h2>

          <button className="close" onClick={() => close()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{
            fill: "#000"
          }}>
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </button>
        </div>

        <div className="modal_content">
          {children}
        </div>
      </div>
    </div>;
}
_c = Modal;
var _c;
$RefreshReg$(_c, "Modal");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;