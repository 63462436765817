// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/sections/image-with-text/ImageWithList.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/sections/image-with-text/ImageWithList.tsx");
}
// REMIX HMR END

import { useState } from "react";
import "./imagewithlist.css?__remix_sideEffect__";
import { createPublicUrl } from "~/utils/helper";
export default function ImageWithList({
  content
}) {
  _s();
  const [isActive, setisActive] = useState(0);
  return <section className="image_with_list_section">
      <div className="container">
        <h2 className="heading">{content.section_title}</h2>
        <div className="row align-itmes-center">
          <div className="col-12">
            <ul className="list">
              <li className={`item ${isActive === 0 ? "active" : ""}`} onMouseOver={() => setisActive(0)}>
                <div className="text_box">
                  <div className="subtitle_box">
                    <div className="icon_box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                        <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path>
                      </svg>
                    </div>
                    <h5 className="subtitle">Step 1</h5>
                  </div>
                  <h4 className="title">{content.list_one_title}</h4>
                  <p className="pera">{content.list_one_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_one_image) ? createPublicUrl(content.list_one_image) : "/image/no-image.svg"} alt="image" />

                </div>
              </li>
              <li className={`item ${isActive === 1 ? "active" : ""}`} onMouseOver={() => setisActive(1)}>
                <div className="text_box">
                  <div className="subtitle_box">
                    <div className="icon_box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                        <path d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z"></path>
                        <path d="M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z"></path>
                      </svg>
                    </div>
                    <h5 className="subtitle">Step 2</h5>
                  </div>
                  <h4 className="title">{content.list_two_title}</h4>
                  <p className="pera">{content.list_two_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_two_image) ? createPublicUrl(content.list_two_image) : "/image/no-image.svg"} alt="image" />

                </div>
              </li>
              <li className={`item ${isActive === 2 ? "active" : ""}`} onMouseOver={() => setisActive(2)}>
                <div className="text_box">
                  <div className="subtitle_box">
                    <div className="icon_box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                        <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM4 18V6h16l.001 12H4z"></path>
                        <path d="M6.5 11h3a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5zM6 14h6v2.001H6zm7 0h5v2.001h-5z"></path>
                      </svg>
                    </div>
                    <h5 className="subtitle">Step 3</h5>
                  </div>
                  <h4 className="title">{content.list_three_title}</h4>
                  <p className="pera">{content.list_three_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_three_image) ? createPublicUrl(content.list_three_image) : "/image/no-image.svg"} alt="image" />

                </div>
              </li>
              <li className={`item ${isActive === 3 ? "active" : ""}`} onMouseOver={() => setisActive(3)}>
                <div className="text_box">
                  <div className="subtitle_box">
                    <div className="icon_box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                        <path d="M20 2H4c-1.103 0-2 .897-2 2v18l5.333-4H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14H6.667L4 18V4h16v12z"></path>
                        <circle cx="15" cy="10" r="2"></circle>
                        <circle cx="9" cy="10" r="2"></circle>
                      </svg>
                    </div>
                    <h5 className="subtitle">Step 4</h5>
                  </div>
                  <h4 className="title">{content.list_four_title}</h4>
                  <p className="pera">{content.list_four_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_four_image) ? createPublicUrl(content.list_four_image) : "/image/no-image.svg"} alt="image" />

                </div>
              </li>
              <li className={`item ${isActive === 4 ? "active" : ""}`} onMouseOver={() => setisActive(4)}>
                <div className="text_box">
                  <div className="subtitle_box">
                    <div className="icon_box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                        <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm7.931 9h-2.764a14.67 14.67 0 0 0-1.792-6.243A8.013 8.013 0 0 1 19.931 11zM12.53 4.027c1.035 1.364 2.427 3.78 2.627 6.973H9.03c.139-2.596.994-5.028 2.451-6.974.172-.01.344-.026.519-.026.179 0 .354.016.53.027zm-3.842.7C7.704 6.618 7.136 8.762 7.03 11H4.069a8.013 8.013 0 0 1 4.619-6.273zM4.069 13h2.974c.136 2.379.665 4.478 1.556 6.23A8.01 8.01 0 0 1 4.069 13zm7.381 6.973C10.049 18.275 9.222 15.896 9.041 13h6.113c-.208 2.773-1.117 5.196-2.603 6.972-.182.012-.364.028-.551.028-.186 0-.367-.016-.55-.027zm4.011-.772c.955-1.794 1.538-3.901 1.691-6.201h2.778a8.005 8.005 0 0 1-4.469 6.201z"></path>
                      </svg>
                    </div>
                    <h5 className="subtitle">Step 5</h5>
                  </div>
                  <h4 className="title">{content.list_five_title}</h4>
                  <p className="pera">{content.list_five_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_five_image) ? createPublicUrl(content.list_five_image) : "/image/no-image.svg"} alt="image" />

                </div>
              </li>
              <li className={`item ${isActive === 5 ? "active" : ""}`} onMouseOver={() => setisActive(5)}>
                <div className="text_box">
                  <div className="subtitle_box">
                    <div className="icon_box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">

                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                      </svg>
                    </div>
                    <h5 className="subtitle">Step 6</h5>
                  </div>
                  <h4 className="title">{content.list_six_title}</h4>
                  <p className="pera">{content.list_six_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_six_image) ? createPublicUrl(content.list_six_image) : "/image/no-image.svg"} alt="image" />

                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>;
}
_s(ImageWithList, "mBmM8C97A2UQ7tD8e4w2nU9dwuI=");
_c = ImageWithList;
var _c;
$RefreshReg$(_c, "ImageWithList");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;