// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/sections/product-grid/ProductGrid.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/sections/product-grid/ProductGrid.tsx");
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import "./productgrid.css?__remix_sideEffect__";
import { useEffect, useState } from "react";
import { createPublicUrl } from "~/utils/helper";
export default function ProductGrid({
  products
}) {
  _s();
  const [populer, setPopuler] = useState([]);
  const [newArrival, setNewArrival] = useState([]);
  const [follows, setFollows] = useState([]);
  useEffect(() => {
    let populerArray = [];
    let newArrivalArray = [];
    let followsArray = [];
    products.forEach((product, index) => {
      if (index <= 3) {
        populerArray.push(product);
      } else if (index > 3 && index <= 6) {
        newArrivalArray.push(product);
      } else if (index > 6 && index <= 9) {
        followsArray.push(product);
      }
    });
    setPopuler(populerArray);
    setNewArrival(newArrivalArray);
    setFollows(followsArray);
  }, [products]);
  return <section className="product_grid_section">
      <div className="container">
        <h2 className="heading">Discover your next business opportunity</h2>
        <div className="row gy-4">
          <div className="col-12 col-lg-4">
            <div className="discover_card">
              <div className="head">
                <div className="left_box">
                  <h2 className="title">Most Popular</h2>
                  <h3 className="subtitle">Start with trends</h3>
                </div>

                <div className="right_box">
                  <Link to="/products" className="view_more">
                    View more
                  </Link>
                </div>
              </div>

              <div className="body_tow">
                {populer?.length >= 3 ? <div className="row">
                    <div className="col-12 col-md-6">
                      <Link to={`/products/${populer[0].id}`} className="product_grid_card md_image">

                        <div className="head">
                          <div className="imgbox_full">
                            <img src={populer[0].images.length ? createPublicUrl(populer[0].images[0]) : "/image/no-image.svg"} alt="" />

                          </div>
                        </div>
                        <div className="body">
                          <div className="title_box">
                            <h2 className="title">{populer[0].name}</h2>
                          </div>
                          <div className="price_box">
                            <h2 className="price">£{populer[0].price}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-md-6">
                      <Link to={`/products/${populer[1].id}`} className="product_grid_card md_image">

                        <div className="head">
                          <div className="imgbox_full">
                            <img src={populer[1].images.length ? createPublicUrl(populer[1].images[0]) : "/image/no-image.svg"} alt="" />

                          </div>
                        </div>
                        <div className="body">
                          <div className="title_box">
                            <h2 className="title">{populer[1].name}</h2>
                          </div>
                          <div className="price_box">
                            <h2 className="price">£{populer[1].price}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-12">
                      <Link to={`/products/${populer[2].id}`} className="product_grid_card lg_image">

                        <div className="head">
                          <div className="imgbox_full">
                            <img src={populer[1].images.length ? createPublicUrl(populer[2].images[0]) : "/image/no-image.svg"} alt="" />

                          </div>
                        </div>
                        <div className="body">
                          <div className="title_box">
                            <h2 className="title">{populer[1].name}</h2>
                          </div>
                          <div className="price_box">
                            <h2 className="price">£{populer[1].price}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div> : null}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="discover_card">
              <div className="head">
                <div className="left_box">
                  <h2 className="title">New Arrival</h2>
                </div>

                <div className="right_box">
                  <Link to="/products" className="view_more">
                    View more
                  </Link>
                </div>
              </div>

              <div className="body_tow">
                {newArrival?.length >= 3 ? <div className="row">
                    <div className="col-12">
                      <Link to={`/products/${newArrival[0].id}`} className="product_grid_card lg_image">

                        <div className="head">
                          <div className="imgbox_full">
                            <img src={newArrival[0].images.length ? createPublicUrl(newArrival[0].images[0]) : "/image/no-image.svg"} alt="" />

                          </div>
                        </div>
                        <div className="body">
                          <div className="title_box">
                            <h2 className="title">{newArrival[0].name}</h2>
                          </div>
                          <div className="price_box">
                            <h2 className="price">£{newArrival[0].price}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-md-6">
                      <Link to={`/products/${newArrival[1].id}`} className="product_grid_card md_image">

                        <div className="head">
                          <div className="imgbox_full">
                            <img src={newArrival[1].images.length ? createPublicUrl(newArrival[1].images[0]) : "/image/no-image.svg"} alt="" />

                          </div>
                        </div>
                        <div className="body">
                          <div className="title_box">
                            <h2 className="title">{newArrival[1].name}</h2>
                          </div>
                          <div className="price_box">
                            <h2 className="price">£{newArrival[1].price}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-md-6">
                      <Link to={`/products/${newArrival[2].id}`} className="product_grid_card md_image">

                        <div className="head">
                          <div className="imgbox_full">
                            <img src={newArrival[2].images.length ? createPublicUrl(newArrival[2].images[0]) : "/image/no-image.svg"} alt="" />

                          </div>
                        </div>
                        <div className="body">
                          <div className="title_box">
                            <h2 className="title">{newArrival[2].name}</h2>
                          </div>
                          <div className="price_box">
                            <h2 className="price">£{newArrival[2].price}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div> : null}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="discover_card">
              <div className="head">
                <div className="left_box">
                  <h2 className="title">Highest follows</h2>
                  <h3 className="subtitle">Explore</h3>
                </div>

                <div className="right_box">
                  <Link to="/products" className="view_more">
                    View more
                  </Link>
                </div>
              </div>

              <div className="body_tow">
              {follows?.length >= 3 ? <div className="row">
                  <div className="col-12">
                    <Link to={`/products/${follows[0].id}`} className="product_grid_card sm_image">

                      <div className="head">
                        <div className="imgbox_full">
                          <img src={follows[0].images.length ? createPublicUrl(follows[0].images[0]) : "/image/no-image.svg"} alt="" />

                        </div>
                      </div>
                      <div className="body">
                        <div className="title_box">
                          <h2 className="title">{follows[0].name}</h2>
                        </div>
                        <div className="price_box">
                          <h2 className="price">£{follows[0].price}</h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-12">
                    <Link to={`/products/${follows[1].id}`} className="product_grid_card sm_image">

                      <div className="head">
                        <div className="imgbox_full">
                          <img src={follows[1].images.length ? createPublicUrl(follows[1].images[0]) : "/image/no-image.svg"} alt="" />

                        </div>
                      </div>
                      <div className="body">
                        <div className="title_box">
                          <h2 className="title">{follows[1].name}</h2>
                        </div>
                        <div className="price_box">
                          <h2 className="price">£{follows[1].price}</h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-12">
                    <Link to={`/products/${follows[2].id}`} className="product_grid_card sm_image">

                      <div className="head">
                        <div className="imgbox_full">
                          <img src={follows[2].images.length ? createPublicUrl(follows[2].images[0]) : "/image/no-image.svg"} alt="" />

                        </div>
                      </div>
                      <div className="body">
                        <div className="title_box">
                          <h2 className="title">{follows[2].name}</h2>
                        </div>
                        <div className="price_box">
                          <h2 className="price">£{follows[2].price}</h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
}
_s(ProductGrid, "CW/RH7aQF+GfhhKbXdnlVPTJCzk=");
_c = ProductGrid;
var _c;
$RefreshReg$(_c, "ProductGrid");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;