// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/sections/advertisement/Advertisement.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/sections/advertisement/Advertisement.tsx");
}
// REMIX HMR END

import { createPublicUrl } from "~/utils/helper";
import "./advertisement.css?__remix_sideEffect__";
import { Link } from "@remix-run/react";
export default function Advertisement({
  content
}) {
  return <section className="advertisement_section">
      <div className="container">
        <h2 className="heading">{content.section_title}</h2>
        <div className="row gy-4">
          <div className="col-12 col-md-6">
            <div className="advertise_card">
              <div className="head">
                <div className="imgbox">
                  <img src={createPublicUrl(content.free_image) ? createPublicUrl(content.free_image) : "/image/no-image.svg"} alt="image" />

                </div>
              </div>
              <div className="body">
                <h4 className="subheading">{content.free_subtitle}</h4>
                <h3 className="heading">{content.free_title}</h3>
                <p className="pera">{content.free_description}</p>

                <ul className="list">
                  {content.free_list_one && <li>
                      <div className="icon_box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">

                          <path d="M22.75 5.9668C23.0547 5.9668 23.3086 6.06836 23.5117 6.27148C23.7318 6.47461 23.8418 6.72852 23.8418 7.0332C23.8418 7.33789 23.7318 7.60026 23.5117 7.82031L10.5117 20.8203C10.3086 21.0234 10.0547 21.125 9.75 21.125C9.44531 21.125 9.19141 21.0234 8.98828 20.8203L2.48828 14.3203C2.26823 14.1003 2.1582 13.8379 2.1582 13.5332C2.1582 13.2285 2.26823 12.9746 2.48828 12.7715C2.69141 12.5684 2.94531 12.4668 3.25 12.4668C3.55469 12.4668 3.80859 12.5684 4.01172 12.7715L9.75 18.5098L21.9883 6.27148C22.1914 6.06836 22.4453 5.9668 22.75 5.9668Z" fill="#2B273C" />

                        </svg>
                      </div>
                      {content.free_list_one}
                    </li>}


                  {content.free_list_two && <li>
                      <div className="icon_box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">

                          <path d="M22.75 5.9668C23.0547 5.9668 23.3086 6.06836 23.5117 6.27148C23.7318 6.47461 23.8418 6.72852 23.8418 7.0332C23.8418 7.33789 23.7318 7.60026 23.5117 7.82031L10.5117 20.8203C10.3086 21.0234 10.0547 21.125 9.75 21.125C9.44531 21.125 9.19141 21.0234 8.98828 20.8203L2.48828 14.3203C2.26823 14.1003 2.1582 13.8379 2.1582 13.5332C2.1582 13.2285 2.26823 12.9746 2.48828 12.7715C2.69141 12.5684 2.94531 12.4668 3.25 12.4668C3.55469 12.4668 3.80859 12.5684 4.01172 12.7715L9.75 18.5098L21.9883 6.27148C22.1914 6.06836 22.4453 5.9668 22.75 5.9668Z" fill="#2B273C" />

                        </svg>
                      </div>
                      {content.free_list_two}
                    </li>}


                  {content.free_list_three && <li>
                      <div className="icon_box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">

                          <path d="M22.75 5.9668C23.0547 5.9668 23.3086 6.06836 23.5117 6.27148C23.7318 6.47461 23.8418 6.72852 23.8418 7.0332C23.8418 7.33789 23.7318 7.60026 23.5117 7.82031L10.5117 20.8203C10.3086 21.0234 10.0547 21.125 9.75 21.125C9.44531 21.125 9.19141 21.0234 8.98828 20.8203L2.48828 14.3203C2.26823 14.1003 2.1582 13.8379 2.1582 13.5332C2.1582 13.2285 2.26823 12.9746 2.48828 12.7715C2.69141 12.5684 2.94531 12.4668 3.25 12.4668C3.55469 12.4668 3.80859 12.5684 4.01172 12.7715L9.75 18.5098L21.9883 6.27148C22.1914 6.06836 22.4453 5.9668 22.75 5.9668Z" fill="#2B273C" />

                        </svg>
                      </div>
                      {content.free_list_three}
                    </li>}


                  {content.free_list_four && <li>
                      <div className="icon_box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">

                          <path d="M22.75 5.9668C23.0547 5.9668 23.3086 6.06836 23.5117 6.27148C23.7318 6.47461 23.8418 6.72852 23.8418 7.0332C23.8418 7.33789 23.7318 7.60026 23.5117 7.82031L10.5117 20.8203C10.3086 21.0234 10.0547 21.125 9.75 21.125C9.44531 21.125 9.19141 21.0234 8.98828 20.8203L2.48828 14.3203C2.26823 14.1003 2.1582 13.8379 2.1582 13.5332C2.1582 13.2285 2.26823 12.9746 2.48828 12.7715C2.69141 12.5684 2.94531 12.4668 3.25 12.4668C3.55469 12.4668 3.80859 12.5684 4.01172 12.7715L9.75 18.5098L21.9883 6.27148C22.1914 6.06836 22.4453 5.9668 22.75 5.9668Z" fill="#2B273C" />

                        </svg>
                      </div>
                      {content.free_list_four}
                    </li>}

                </ul>

                <Link to={"/pricing"} className="read_more">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="advertise_card">
              <div className="head">
                <div className="imgbox">
                  <img src={createPublicUrl(content.premium_image) ? createPublicUrl(content.premium_image) : "/image/no-image.svg"} alt="image" />

                </div>
              </div>
              <div className="body">
                <h4 className="subheading">{content.premium_subtitle}</h4>
                <h3 className="heading">{content.premium_title}</h3>
                <p className="pera">{content.premium_description}</p>

                <ul className="list">
                  {content.premium_list_one && <li>
                      <div className="icon_box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">

                          <path d="M22.75 5.9668C23.0547 5.9668 23.3086 6.06836 23.5117 6.27148C23.7318 6.47461 23.8418 6.72852 23.8418 7.0332C23.8418 7.33789 23.7318 7.60026 23.5117 7.82031L10.5117 20.8203C10.3086 21.0234 10.0547 21.125 9.75 21.125C9.44531 21.125 9.19141 21.0234 8.98828 20.8203L2.48828 14.3203C2.26823 14.1003 2.1582 13.8379 2.1582 13.5332C2.1582 13.2285 2.26823 12.9746 2.48828 12.7715C2.69141 12.5684 2.94531 12.4668 3.25 12.4668C3.55469 12.4668 3.80859 12.5684 4.01172 12.7715L9.75 18.5098L21.9883 6.27148C22.1914 6.06836 22.4453 5.9668 22.75 5.9668Z" fill="#2B273C" />

                        </svg>
                      </div>
                      {content.premium_list_one}
                    </li>}


                  {content.premium_list_two && <li>
                      <div className="icon_box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">

                          <path d="M22.75 5.9668C23.0547 5.9668 23.3086 6.06836 23.5117 6.27148C23.7318 6.47461 23.8418 6.72852 23.8418 7.0332C23.8418 7.33789 23.7318 7.60026 23.5117 7.82031L10.5117 20.8203C10.3086 21.0234 10.0547 21.125 9.75 21.125C9.44531 21.125 9.19141 21.0234 8.98828 20.8203L2.48828 14.3203C2.26823 14.1003 2.1582 13.8379 2.1582 13.5332C2.1582 13.2285 2.26823 12.9746 2.48828 12.7715C2.69141 12.5684 2.94531 12.4668 3.25 12.4668C3.55469 12.4668 3.80859 12.5684 4.01172 12.7715L9.75 18.5098L21.9883 6.27148C22.1914 6.06836 22.4453 5.9668 22.75 5.9668Z" fill="#2B273C" />

                        </svg>
                      </div>
                      {content.premium_list_two}
                    </li>}


                  {content.premium_list_three && <li>
                      <div className="icon_box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">

                          <path d="M22.75 5.9668C23.0547 5.9668 23.3086 6.06836 23.5117 6.27148C23.7318 6.47461 23.8418 6.72852 23.8418 7.0332C23.8418 7.33789 23.7318 7.60026 23.5117 7.82031L10.5117 20.8203C10.3086 21.0234 10.0547 21.125 9.75 21.125C9.44531 21.125 9.19141 21.0234 8.98828 20.8203L2.48828 14.3203C2.26823 14.1003 2.1582 13.8379 2.1582 13.5332C2.1582 13.2285 2.26823 12.9746 2.48828 12.7715C2.69141 12.5684 2.94531 12.4668 3.25 12.4668C3.55469 12.4668 3.80859 12.5684 4.01172 12.7715L9.75 18.5098L21.9883 6.27148C22.1914 6.06836 22.4453 5.9668 22.75 5.9668Z" fill="#2B273C" />

                        </svg>
                      </div>
                      {content.premium_list_three}
                    </li>}


                  {content.premium_list_four && <li>
                      <div className="icon_box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">

                          <path d="M22.75 5.9668C23.0547 5.9668 23.3086 6.06836 23.5117 6.27148C23.7318 6.47461 23.8418 6.72852 23.8418 7.0332C23.8418 7.33789 23.7318 7.60026 23.5117 7.82031L10.5117 20.8203C10.3086 21.0234 10.0547 21.125 9.75 21.125C9.44531 21.125 9.19141 21.0234 8.98828 20.8203L2.48828 14.3203C2.26823 14.1003 2.1582 13.8379 2.1582 13.5332C2.1582 13.2285 2.26823 12.9746 2.48828 12.7715C2.69141 12.5684 2.94531 12.4668 3.25 12.4668C3.55469 12.4668 3.80859 12.5684 4.01172 12.7715L9.75 18.5098L21.9883 6.27148C22.1914 6.06836 22.4453 5.9668 22.75 5.9668Z" fill="#2B273C" />

                        </svg>
                      </div>
                      {content.premium_list_four}
                    </li>}

                </ul>

                <Link to={"/pricing"} className="read_more">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
}
_c = Advertisement;
var _c;
$RefreshReg$(_c, "Advertisement");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;