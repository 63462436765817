// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1732818820214.8774";
}
// REMIX HMR END

import { useActionData, useLoaderData } from "@remix-run/react";
import { json } from "@remix-run/node";
import Layout from "~/components/landingPage/layouts/Layout";
import Advertisement from "~/components/landingPage/sections/advertisement/Advertisement";
import FeatureCard from "~/components/landingPage/sections/feature-card/FeatureCard";
import HomeBanner from "~/components/landingPage/sections/home-banner/HomeBanner";
import ImageWithList from "~/components/landingPage/sections/image-with-text/ImageWithList";
import ListSlider from "~/components/landingPage/sections/list-slider/ListSlider";
import ProductGrid from "~/components/landingPage/sections/product-grid/ProductGrid";
import Testimonial from "~/components/landingPage/sections/testimonial/Testimonial";
import ProductCategoryService from "~/services/productCategory.service";
import categoryService from "~/services/category.service";
import { getContent } from "~/models/content.server";
import productService from "~/services/product.service";
import newsletterService from "~/services/newsletter.service";
import { useEffect } from "react";
import { handleSuccessToast } from "~/utils";
import blogService from "~/services/blog.service";
import ResourceSection from "~/components/landingPage/sections/resource-section/ResourceSection";
import reviewService from "~/services/review.service";
export const loader = async loaderFunctionArgs => {
  const data = await ProductCategoryService.listLoader(loaderFunctionArgs);
  const busenessData = await categoryService.listLoader(loaderFunctionArgs);
  const productContent = await productService.getAllProduct(loaderFunctionArgs);
  const contactContent = await getContent("CONTACT");
  const homeContent = await getContent("HOME");
  const sectionContent = homeContent?.section;
  const {
    blogs
  } = await blogService.fetchBlogs(loaderFunctionArgs.request);
  const {
    reviews
  } = await reviewService.fetchReviews(loaderFunctionArgs.request);
  return json({
    categories: data.categories,
    busenessCategories: busenessData.categories,
    sectionContent,
    productContent,
    contactContent,
    blogs,
    reviews
  });
};
export const action = async ({
  request
}) => {
  const formData = await request.formData();
  const formDataObject = Object.fromEntries(formData.entries());
  const data = {
    email: formDataObject.email
  };
  const response = await newsletterService.createNewsletter(data);
  return json({
    success: response.success,
    msg: response.msg,
    errors: response.errors
  }, {
    status: response.status
  });
};
export const meta = () => [{
  title: "ALL IN ONE | Home"
}];
export default function Index() {
  _s();
  const {
    categories,
    sectionContent,
    productContent,
    contactContent,
    busenessCategories,
    blogs,
    reviews
  } = useLoaderData();
  const {
    banner,
    category,
    solution,
    success
  } = sectionContent;
  const {
    products
  } = productContent;
  const actionData = useActionData();
  useEffect(() => {
    if (actionData?.success && actionData?.msg) {
      handleSuccessToast(actionData.msg);
    }
  }, [actionData]);
  return <Layout contact={contactContent.section.contact}>
      <HomeBanner content={banner} />
      <FeatureCard products={products} categories={categories} />
      <ListSlider categories={busenessCategories} content={category} />
      <Advertisement content={solution} />
      {products && products.length > 0 && <ProductGrid products={products} />}
      <ImageWithList content={success} />
      {reviews && reviews.length > 0 && <Testimonial reviews={reviews} />}
      {blogs && blogs.length > 0 && <ResourceSection blogs={blogs} />}
    </Layout>;
}
_s(Index, "OGPhurmtKdPETvw6qC6A7Jc/7U8=", false, function () {
  return [useLoaderData, useActionData];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;